import axios from 'axios'
import NetworkClientError from '@/common/errors/NetworkClientError'
import NetworkRedirectError from '@/common/errors/NetworkRedirectError'
import NetworkServerError from '@/common/errors/NetworkServerError'
import goLoginPage from '@/common/goLoginPage'
import {
  replaceRestfulParams,
  concatQuery,
  deepMerge,
  objPropContainStr,
} from '@/utils'

const redirectStatusReg = /^3/
const clientErrStatusReg = /^4/
const serverErrStatusReg = /^5/

const prefix = ''

export default function request({
  query = {},
  params = {},
  options = {},
  body,
  url,
  method = 'GET',
  headers = {},
  handle401 = true,
}) {
  const defaultConfig = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include',
    mode: 'cors',
    xsrfHeaderName: 'X-Secure-Token',
    xsrfCookieName: 'secure_token',
  }
  const config = deepMerge(defaultConfig, options)

  url = `${prefix}${url}`
  url = replaceRestfulParams(url, params)
  url = concatQuery(url, query)

  if (body) {
    const isJSONContentType = objPropContainStr(
      config.headers,
      'Content-Type',
      'application/json'
    )
    if (isJSONContentType) {
      body = JSON.stringify(body)
    }
    config.data = body
  }

  return axios(url, config)
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      const { response } = error
      if (response) {
        const { status, statusText, data } = response
        if (status === 401 && handle401) {
          goLoginPage()
          throw new Error('请重新登录')
        }
        if (redirectStatusReg.test(status)) {
          throw new NetworkRedirectError({
            status,
            statusText,
            data,
            message: data.message || `请求被服务端重定向`,
          })
        } else if (clientErrStatusReg.test(status)) {
          throw new NetworkClientError({
            status,
            statusText,
            data,
            message: data.message || '客户端错误',
          })
        } else if (serverErrStatusReg.test(status)) {
          throw new NetworkServerError({
            status,
            statusText,
            data,
            message: data.message || '服务端错误',
          })
        }
      } else {
        throw error
      }
    })
}

export const get = (url, query = {}) => {
  return request({
    url,
    query,
  })
}
