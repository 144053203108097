import request from './request'

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/api/v1/center/user',
    handle401: false,
  })
}

// 登录
export function login(params) {
  return request({
    url: '/api/v1/auth/login',
    query: params,
  })
}

// 退出登录
export function logout() {
  return request({
    url: '/api/v1/auth/logout',
  })
}
