import request from './request'

export function getIdCardCheck() {
  return request({
    url: '/api/v1/center/personal/attachment/idcard/check',
  })
}

export function createIdCard(body) {
  return request({
    url: '/api/v1/center/personal/attachment/idcard',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body,
  })
}

export function deleteIdCard() {
  return request({
    url: '/api/v1/center/personal/attachment/idcard',
    method: 'DELETE',
  })
}
