class BaseError {
  constructor(...args) {
    const error = new Error(...args)

    error.name = 'BaseError'
    return error
  }
}

export default BaseError
