export function trackEvent(eventName, params) {
  if (window.onetrack) {
    window.onetrack('track', eventName, { ...params })
  }
}

export function trackPage(page) {
  trackEvent('view', {
    tip: '544.1.0.1.11834',
    element_name: page,
  })
}

export function trackClick(name) {
  trackEvent('click', {
    tip: '544.1.0.1.11835',
    element_name: name,
  })
}
