import NetworkError from './NetworkError'

class NetworkClientError extends NetworkError {
  constructor(args) {
    super(args)
    this.name = 'NetworkClientError'
  }
}

export default NetworkClientError
