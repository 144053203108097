import * as api from '@/api/login.js'
import {
  getPersonalAddress,
  getAreas,
  getPersonalBankCards,
} from '@/api/center'
import { message } from 'antd'
import { getIdCardCheck } from '../api/idCard'

export default {
  namespace: 'global',

  state: {
    language: 'zh_CN',
    collapsed: false,
    userInfo: {},
    addresses: [],
    areaList: [],
    banks: [],
    curBreadcrumbsList: [],
    selectedGift: null,
    idCardCheck: false,
  },

  effects: {
    *getUserInfo(_, { call, put }) {
      try {
        const { data } = yield call(api.getUserInfo)
        yield put({
          type: 'save',
          payload: {
            userInfo: data,
          },
        })
        return true
      } catch (err) {
        return false
      }
    },
    *getUserAddress(_, { call, put }) {
      try {
        const { data } = yield call(getPersonalAddress)
        yield put({
          type: 'save',
          payload: {
            addresses: data,
          },
        })
        return true
      } catch (err) {
        return false
      }
    },

    *getAreas(_, { call, put }) {
      let areas
      try {
        areas = JSON.parse(localStorage.getItem('_areas'))
        if (!areas) {
          throw new Error()
        }
      } catch (e) {
        try {
          const data = yield call(getAreas)
          areas = data.map((province) => {
            return {
              label: province.pname,
              value: province.pid,
              children: province.cities.map((city) => {
                return {
                  label: city.cname,
                  value: city.cid,
                  children: city.areas.map((area) => {
                    return {
                      label: area.aname,
                      value: area.aid,
                      children: area.towns.map((town) => {
                        return {
                          label: town.tname,
                          value: town.tid,
                        }
                      }),
                    }
                  }),
                }
              }),
            }
          })
          localStorage.setItem('_areas', JSON.stringify(areas))
        } catch (e) {
          // message.error(e.message)
        }
      }
      if (areas) {
        yield put({
          type: 'save',
          payload: {
            areaList: areas,
          },
        })
      }
    },

    *getPersonalBankCards(_, { call, put }) {
      try {
        const { data } = yield call(getPersonalBankCards)
        yield put({
          type: 'save',
          payload: {
            banks: data,
          },
        })
        return true
      } catch {
        return false
      }
    },
    *getIdCardCheck(_, { call, put }) {
      try {
        const { data } = yield call(getIdCardCheck)
        yield put({
          type: 'save',
          payload: {
            idCardCheck: data,
          },
        })
        return true
      } catch (error) {
        return false
      }
    },

    *login({ payload: { params } }, { call }) {
      try {
        yield call(api.login, params)
      } catch (err) {
        message.error(err.message)
      }
    },
    *logout(_, { call }) {
      try {
        yield call(api.logout)
      } catch (err) {
        message.error(err.message)
      }
    },
    *setCurBreadcrumbsList({ payload: curPathName }, { put, select }) {
      const routerPathMap = yield select((state) => state.menu.routerPathMap)
      const isEmpty = Object.keys(routerPathMap).length === 0
      if (isEmpty) {
        return
      }
      function getCurBreadcrumbsList(
        routerPathMap,
        pathName,
        curBreadcrumbsList = []
      ) {
        const pathConf = routerPathMap[pathName]

        if (pathConf) {
          curBreadcrumbsList.unshift({
            path: pathConf.path,
            breadcrumbName: pathConf.title,
          })
          if (pathConf.parentPath) {
            getCurBreadcrumbsList(
              routerPathMap,
              pathConf.parentPath,
              curBreadcrumbsList
            )
          }
        }

        return curBreadcrumbsList
      }

      const curBreadcrumbsList = getCurBreadcrumbsList(
        routerPathMap,
        curPathName
      )

      curBreadcrumbsList.unshift({
        path: '/',
        breadcrumbName: '首页',
      })

      yield put({
        type: 'save',
        payload: {
          curBreadcrumbsList,
        },
      })
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload,
      }
    },
  },

  subscriptions: {
    setup({ history, dispatch }) {
      return history.listen(({ pathname }) => {
        window.scrollTo(0, 0)
        if (pathname.indexOf('/notice') > -1) {
          pathname = '/notice'
        }
        if (pathname.indexOf('/blogdetail') > -1) {
          pathname = '/blog'
        }
        if (pathname.indexOf('/IoT') > -1) {
          pathname = '/IoT'
        }
        if (pathname.indexOf('/hero/detail') > -1) {
          pathname = '/hero'
        }

        dispatch({
          type: 'setCurBreadcrumbsList',
          payload: pathname,
        })
        dispatch({
          type: 'menu/setMenuKeys',
          payload: pathname,
        })
        dispatch({
          type: 'menu/setTopMenuKeys',
          payload: pathname,
        })
      })
    },
  },
}
