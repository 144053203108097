import NetworkError from './NetworkError'

class NetworkServerError extends NetworkError {
  constructor(args) {
    super(args)
    this.name = 'NetworkServerError'
  }
}

export default NetworkServerError
