import NetworkError from './NetworkError'

class NetworkRedirectError extends NetworkError {
  constructor(args) {
    super(args)
    this.name = 'NetworkRedirectError'
  }
}

export default NetworkRedirectError
