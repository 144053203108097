import * as Sentry from '@sentry/browser'
import '@/common/handleUncaughtError'
import { trackPage } from '@/utils/track'
import './polyfill'
import start from './router'
import detectIE from './utils/ie'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://3ad4102c7ea245af8dd85b7e651a4d8e@sentry.d.xiaomi.net/303',
  })
}

const ie = detectIE()
if (ie > 0 && ie < 11) {
  document.getElementById('oldbrowser').style.display = 'block'
  document.getElementById('root').style.display = 'none'
} else {
  start()
  trackPage('entry')
}
