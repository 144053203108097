/* eslint-disable prefer-promise-reject-errors */

import { merge } from 'lodash'
import qs from 'qs'
import { unescape } from 'html-escaper'
import xss from 'xss'

function getLocation(href) {
  const l = document.createElement('a')
  l.href = href
  return l
}

export function replaceRestfulParams(url, params) {
  const path = getLocation(url).pathname
  const restParamSegReg = /:([^/]*)/g
  const replacedPath = path
    .replace(restParamSegReg, (_, seg) => {
      let val = params[seg]
      if (val === undefined) {
        val = `:${seg}`
      }
      return val
    })
    .replace('//', '/')
  return url.replace(path, replacedPath)
}

function stringifyQueryObj(queryObj) {
  return Object.keys(queryObj)
    .map((key) => `${key}=${encodeURIComponent(queryObj[key])}`)
    .join('&')
}

export function concatQuery(url, query = {}) {
  if (Object.keys(query).length === 0) {
    return url
  }
  if (url.indexOf('?') === -1) {
    url = `${url}?`
  }
  const stringifiedQuery = stringifyQueryObj(query)
  if (url[url.length - 1] === '?') {
    return `${url}${stringifiedQuery}`
  }
  return `${url}&${stringifiedQuery}`
}

export function deepMerge(...args) {
  return merge({}, ...args)
}

export function objPropContainStr(
  obj,
  prop,
  expectedContainStr,
  propKeyCaseInsenstive = true
) {
  let value = obj[prop] || ''
  if (value.indexOf(expectedContainStr) > -1) {
    return true
  }
  if (propKeyCaseInsenstive) {
    value = obj[prop.toLowerCase()] || ''
    if (value.indexOf(expectedContainStr) > -1) {
      return true
    }
  }

  return false
}

export function getUniqId() {
  return Math.random().toString(36).substr(2, 9)
}

export function UrlParamHash(url) {
  if (url.indexOf('?') === -1) return {}
  const hash = url.slice(url.indexOf('?') + 1).split('&')
  const params = {}
  hash.forEach((it) => {
    const param = qs.parse(it)
    Object.assign(params, param)
  })
  return params
}

export function unescapeToSafeHtmlStr(str) {
  // 先反转义再去除危险标签
  return xss(unescape(str))
}

export const validPhone = (str) => {
  return /^[0-9+]{4,}$/.test(str)
}

export const validBankNum = (str) => {
  return /^[0-9]{16,19}$/.test(str)
}

export const validIdCardNum = (str) => {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(str)
}

export const validEmail = (str) => {
  return /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(str)
}

export const phoneValidator = (rules, value) => {
  if (!validPhone(value)) {
    return Promise.reject('手机号码格式错误')
  }
  return Promise.resolve()
}

export const idCardValidator = (rules, value) => {
  if (!validIdCardNum(value)) {
    return Promise.reject('身份证号格式错误')
  }
  return Promise.resolve()
}

export const bankCardValidator = (rules, value) => {
  if (!validBankNum(value)) {
    return Promise.reject('银行卡号格式错误')
  }
  return Promise.resolve()
}

export const emailValidator = (rules, value) => {
  if (!validEmail(value)) {
    return Promise.reject('邮箱格式错误')
  }
  return Promise.resolve()
}
