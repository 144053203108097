import BaseError from './BaseError'

class NetworkError extends BaseError {
  constructor({ message, status, statusText, data }) {
    super(message)
    this.name = 'NetworkError'
    this.data = data
    this.status = status
    this.statusText = statusText
  }
}

export default NetworkError
