import request, { get } from './request'

// 个人中心 - 个人中心信息
export function getPersonalProfile() {
  return request({
    url: `/api/v1/center/profile`,
  })
}

// 我的信息 - 用户基本信息
export function getPersonalBasic() {
  return request({
    url: `/api/v1/center/personal/basic`,
  })
}

// 我的信息 - 编辑用户基本信息
export function updatePersonalProfile(params) {
  return request({
    url: `/api/v1/center/personal/basic`,
    method: 'PATCH',
    body: params,
  })
}

// 我的信息 - 编辑用户头像
export function updatePersonalAvatar(params) {
  return request({
    url: `/api/v1/center/personal/avatar`,
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params,
  })
}

// 我的信息 - 获取地址列表
export function getPersonalAddress(params) {
  return request({
    url: `/api/v1/center/personal/addresses`,
    query: params,
  })
}

// 我的信息 - 获取地址列表
export function addPersonalAddress(params) {
  return request({
    url: `/api/v1/center/personal/address`,
    method: 'POST',
    body: params,
  })
}

// 我的信息 - 删除地址
export function deletePersonalAddress(params) {
  return request({
    url: `/api/v1/center/personal/address`,
    method: 'DELETE',
    query: params,
  })
}

// 我的信息 - 编辑地址
export function editPersonalAddress(body, query) {
  return request({
    url: `/api/v1/center/personal/address`,
    method: 'PATCH',
    query,
    body,
  })
}

// 我的信息 - 获取银行卡列表
export function getPersonalBankCards() {
  return request({
    url: `/api/v1/center/personal/bankcards`,
  })
}

// 我的信息 - 添加银行卡
export function addPersonalBankCard(params) {
  return request({
    url: `/api/v1/center/personal/bankcard`,
    method: 'POST',
    body: params,
  })
}

// 我的信息 - 获取银行卡详情
export function getPersonalBankCardDetail(params) {
  return request({
    url: `/api/v1/center/personal/bankcard`,
    query: params,
  })
}

// 我的信息 - 编辑银行卡
export function editPersonalBankCard(body, query) {
  return request({
    url: `/api/v1/center/personal/bankcard`,
    method: 'PATCH',
    query,
    body,
  })
}

// 我的信息 - 删除银行卡
export function deletePersonalBankCard(params) {
  return request({
    url: `/api/v1/center/personal/bankcard`,
    method: 'DELETE',
    query: params,
  })
}

// 我的消息 - 获取消息列表
export function getMessageList(params) {
  return request({
    url: `/api/v1/center/messages`,
    query: params,
  })
}

// 我的消息 - 标记所选消息已读
export function updateMessageStatus(params) {
  return request({
    url: `/api/v1/center/messages`,
    method: 'PATCH',
    query: params,
  })
}

// 我的消息 - 删除所选消息
export function deleteMessage(params) {
  return request({
    url: `/api/v1/center/messages`,
    method: 'DELETE',
    query: params,
  })
}

// 漏洞列表 - 获取漏洞列表
export function getBugList(params) {
  return request({
    url: `/api/v1/center/bugs`,
    query: params,
  })
}

// 漏洞列表 - 获取漏洞列表
export function getOrderList(params) {
  return request({
    url: `/api/v1/center/orders`,
    query: params,
  })
}

// 漏洞列表 - 获取漏洞分类
export function getBugStatues() {
  return request({
    url: `/api/v1/open/bug/statuses`,
  })
}

// 我的消息 - 获取信息分类
export function getMessageCategories() {
  return request({
    url: `/api/v1/open/message/categories`,
  })
}

// 礼物兑换 - 获取信息分类
export function getOrderStatues() {
  return request({
    url: `/api/v1/open/order/statuses`,
  })
}

// 我的团队 - 创建团队
export function createTeam(params) {
  return request({
    url: `/api/v1/center/team`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params,
  })
}

// 我的团队 - 加入团队
export function joinTeam(params) {
  return request({
    url: `/api/v1/center/team/application`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params,
  })
}

// 我的团队 - 获取团队列表
export function getTeamList() {
  return request({
    url: `/api/v1/open/teams`,
  })
}

export function deleteMember(hash) {
  const params = new FormData()
  params.append('hash', hash)
  return request({
    url: `/api/v1/center/team/member`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params,
  })
}

// 我的团队 - 获取团队信息
export function getTeamProfile() {
  return request({
    url: `/api/v1/center/team`,
  })
}

// 我的团队 - 获取团队成员
export function getTeamMembers(params) {
  return request({
    url: `/api/v1/center/team/members`,
    query: params,
  })
}

// 我的团队 - 获取团队管理列表
export function getTeamApplications(params) {
  return request({
    url: `/api/v1/center/team/applications`,
    query: params,
  })
}

// 我的团队 - 审批团队申请
export function upudateTeamApplication(params) {
  return request({
    url: `/api/v1/center/team/application`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: params,
  })
}

// 我的信息 - 地区列表
export function getAreas() {
  return request({
    url: `/api/v1/open/areas`,
  })
}

export function getUserProfile(hash) {
  return get(`/api/v1/open/user/profile`, {
    hash,
  })
}
