import { HashRouter, Route, Redirect, Switch } from 'react-router-dom'
import React from 'react'
import zhCN from 'antd/es/locale-provider/zh_CN'
import { ConfigProvider } from 'antd'

import routerConfig from '@/config/router.config'
import { getUniqId } from '@/utils'

import app from './dva'
import './i18n'

import './tinymce.less'

const RouteInstanceMap = {
  get(key) {
    return key.$$$routeInternalComponent
  },
  has(key) {
    return key.$$$routeInternalComponent !== undefined
  },
  set(key, value) {
    key.$$$routeInternalComponent = value
  },
}

// Support pass props from layout to child routes
const RouteWithProps = ({
  path,
  exact,
  strict,
  render,
  location,
  sensitive,
  ...rest
}) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    sensitive={sensitive}
    render={(props) => render({ ...props, ...rest })}
  />
)

function withRoutes(route) {
  if (RouteInstanceMap.has(route)) {
    return RouteInstanceMap.get(route)
  }

  const { Routes } = route
  let len = Routes.length - 1
  let Component = (args) => {
    const { render, ...props } = args
    return render(props)
  }
  while (len >= 0) {
    const AuthRoute = Routes[len]
    const OldComponent = Component
    Component = (props) => (
      <AuthRoute {...props}>
        <OldComponent {...props} />
      </AuthRoute>
    )
    len -= 1
  }

  const ret = (args) => {
    const { render, ...rest } = args
    return (
      <RouteWithProps
        {...rest}
        render={(props) => {
          return <Component {...props} route={route} render={render} />
        }}
      />
    )
  }
  RouteInstanceMap.set(route, ret)
  return ret
}

function renderRoutes(routes) {
  return routes ? (
    <Switch>
      {routes.map((route) => {
        if (route.redirect) {
          return (
            <Redirect
              key={route.key || getUniqId()}
              from={route.path}
              to={route.redirect}
              exact={route.exact}
              strict={route.strict}
            />
          )
        }
        const RouteRoute = route.Routes ? withRoutes(route) : RouteWithProps
        return (
          <RouteRoute
            key={route.key || getUniqId()}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            sensitive={route.sensitive}
            render={(props) => {
              const childRoutes = renderRoutes(route.routes)
              if (route.component) {
                return (
                  <route.component {...props}>{childRoutes}</route.component>
                )
              }
              return childRoutes
            }}
          />
        )
      })}
    </Switch>
  ) : null
}

function Router() {
  const routeNode = renderRoutes(routerConfig, true)

  return (
    <HashRouter>
      <ConfigProvider locale={zhCN}>{routeNode}</ConfigProvider>
    </HashRouter>
  )
}

const start = () => {
  app.router(Router)
  app.start('#root')
}

export default start
