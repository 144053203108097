import routerConfig from '@/config/router.config'

function getMenuData() {
  const menuData = []
  const routerPathMap = {}
  const iterateRouterList = (list, menuData, parentRoutePath) => {
    list.forEach((route) => {
      if (route.path === '/' && route.routes) {
        iterateRouterList(route.routes, menuData, route.path)
        return
      }
      if (!route.title || route.hideInMenu) {
        return
      }
      const menuItem = {
        icon: route.icon,
        path: route.path,
        title: route.title,
        component: route.component,
        redirect: route.redirect,
        parentPath: parentRoutePath,
      }
      menuData.push(menuItem)

      routerPathMap[route.path] = menuItem

      if (route.routes && route.routes.length > 0) {
        const subMenuData = []
        menuItem.children = subMenuData
        iterateRouterList(route.routes, subMenuData, route.path)
      }
    })
  }

  iterateRouterList(routerConfig, menuData)

  return {
    menuData,
    routerPathMap,
  }
}

export default {
  namespace: 'menu',

  state: {
    menuData: [],
    routerPathMap: {},
    selectedKeys: [],
    topMenuKeys: [],
    openKeys: [],
  },

  effects: {
    *getMenuData(_, { put }) {
      const { menuData, routerPathMap } = getMenuData()
      yield put({
        type: 'save',
        payload: {
          menuData,
          routerPathMap,
        },
      })
    },
    *setTopMenuKeys({ payload: topMenuKeys }, { put }) {
      yield put({
        type: 'save',
        payload: {
          topMenuKeys: [topMenuKeys],
        },
      })
    },
    *setMenuKeys({ payload: curPathName }, { put, select }) {
      const routerPathMap = yield select((state) => state.menu.routerPathMap)
      const curPathConf = routerPathMap[curPathName]
      const selectedKeys = []
      let openKeys = []
      function getOpenKeys(routerPathMap, pathName, openKeys = []) {
        const pathConf = routerPathMap[pathName]

        if (pathConf) {
          openKeys.push(pathConf.path)
          if (pathConf.parentPath) {
            getOpenKeys(routerPathMap, pathConf.parentPath, openKeys)
          }
        }

        return openKeys
      }

      if (curPathConf) {
        selectedKeys.push(curPathConf.path)
        openKeys = getOpenKeys(routerPathMap, curPathName)
      }
      yield put({
        type: 'save',
        payload: {
          openKeys,
          selectedKeys,
        },
      })
    },
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
}
