// i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguage } from '@/utils/getLanguage'
import zh from './locales/zh_CN'
import en from './locales/en_US'

const resources = {
  zh_CN: {
    translation: {
      ...zh,
    },
  },
  en_US: {
    translation: {
      ...en,
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguage(),
    // fallbackLng: 'zh_CN',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
